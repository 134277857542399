import React from "react"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import Label from "../components/label"
import Heading from "../components/heading"
import { ItemsList } from "../components/utils"
import { Phone, Send } from "react-feather"

const ContactDetails = ({ contact, title, people }) => (
  <Wrapper>
    <Label>LTE Impianti</Label>
    <Heading as="h3" color={DesignTokens.colors.secondary[500]} downcase>
      {title}
    </Heading>
    <ItemsList>
      <Contact>
        <Send size={20} />
        <a
          href={`mailto:` + contact.email}
          target="_blank"
          rel="noopener noreferrer"
        >
          {contact.email}
        </a>
      </Contact>
      <Contact>
        <Phone size={20} />
        <a
          href={`tel:` + contact.phone}
          target="_blank"
          rel="noopener noreferrer"
        >
          {contact.phone}
        </a>
      </Contact>
    </ItemsList>
    <People people={people} />
    <AdditionalInformation
      dangerouslySetInnerHTML={{ __html: contact.additionalInfo }}
    />
  </Wrapper>
)

const People = ({ people }) => (
  <ItemsList>
    {people.map(person => (
      <Person key={person.id}>
        <h4>
          <a href={"mailto:" + person.email}>{person.name}</a>
        </h4>
        <p>{person.department}</p>
      </Person>
    ))}
  </ItemsList>
)

const Wrapper = styled.div`
  color: ${DesignTokens.colors.white};
  font-size: ${DesignTokens.fontSizes[4]};
  h2 {
    margin-bottom: ${DesignTokens.spacing[8]};
  }
  ul {
    margin-bottom: ${DesignTokens.spacing[8]};
  }
  a {
    color: ${DesignTokens.colors.white};
  }
`

const Contact = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  svg {
    margin-right: ${DesignTokens.spacing[3]};
  }
`

const Person = styled.li`
  display: flex;
  flex-direction: column;
  h4 {
      font-size: ${DesignTokens.fontSizes[5]};
      margin-bottom: ${DesignTokens.spacing[2]};
  }
`

const AdditionalInformation = styled.div`
  p {
    margin-bottom: ${DesignTokens.spacing[2]};
  }
`

export default ContactDetails
